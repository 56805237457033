import React from "react"
import PageTransition from "gatsby-v2-plugin-page-transitions"
import Layout from "../components/layout"
import Projects from "../components/projects"
import Seo from "../components/seo"

export default function PageProjects() {
  const currentLang = "en"
  return (
    <PageTransition>
      <Layout lang={currentLang} bodyColor={'white'}>
        <Seo
          lang={currentLang}
          title={"Projects"}
          keywords={[`powszuk`, `projects`]}
        />
        <Projects lang={currentLang} />
      </Layout>
    </PageTransition>
  )
}
